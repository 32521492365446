import { ILocation } from "contracts";
import { ICity, IOffice } from "models";

const R = 6371e3; // Earth's radius in meters.
const radFactor = Math.PI / 180;

export function distanceBetweenPoints(p1: ILocation, p2: ILocation): number {
    // Haversine distance formula
    // https://www.movable-type.co.uk/scripts/latlong.html

    const φ1 = p1.latitude * radFactor; // φ, λ in radians
    const φ2 = p2.latitude * radFactor;
    const Δφ = (p2.latitude - p1.latitude) * radFactor;
    const Δλ = (p2.longitude - p1.longitude) * radFactor;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // distance in meters
}

export function sortByProximityToLocation(location: ILocation) {
    return (office1: IOffice, office2: IOffice) => {
        const dist1 = distanceBetweenPoints(location, office1.address.location);
        const dist2 = distanceBetweenPoints(location, office2.address.location);

        office1.distance = dist1;
        office2.distance = dist2;

        if (dist1 < dist2) {
            return -1;
        }

        if (dist1 > dist2) {
            return 1;
        }

        return 0;
    }
}

export const findCity = async (
    potentialCities: string[] | undefined,
    fetchCities: (query: string) => Promise<ICity[]>
): Promise<ICity | undefined> => {
    if (!potentialCities) {
        return;
    }

    for (let i = 0; i < potentialCities.length; i++) {
        const cities = await fetchCities(potentialCities[i]);
        if (!cities) {
            continue;
        }
        if (cities.length > 0) {
            return cities[0]
        }
    }
};